import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const Icon = makeShortcode("Icon");
const Link = makeShortcode("Link");
const Process = makeShortcode("Process");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const Tout = makeShortcode("Tout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Working on a Bounty`}</h1>
    <p>{`Bounties come in many shapes and sizes but there are a few ways to make sure things run smoothly. Here’s the recommended workflow for new contributors.`}</p>
    <CTA mdxType="CTA">
      <Icon size={"40px"} name="liquidation" mdxType="Icon" />  Make sure to express interest in the chat before working on a bounty.{" "} <Link to="https://chat.makerdao.com/channel/community-development" mdxType="Link"> Community Development </Link>
    </CTA>
    <h2>{`The Process`}</h2>
    <Process mdxType="Process">
      <Box mdxType="Box">
        <h3>{`Work With the Community Team`}</h3>
        <p>{`Most medium to large bounties require collaboration with the Contributing Team. Contributors working on bounties are assigned an Advisor, and in some cases, introduced to other contributors.`}</p>
        <p>{`Work together to develop a plan that helps structure the approach:`}</p>
        <ul>
          <li parentName="ul">{`Clear, attainable goals`}</li>
          <li parentName="ul">{`Realistic timelines and deadlines`}</li>
          <li parentName="ul">{`The best way to split up any work`}</li>
          <li parentName="ul">{`Roles and responsibilities`}</li>
          <li parentName="ul">{`Times to sync up`}</li>
        </ul>
        <p>{`Need help getting started? Utilize a project plan template.`}</p>
        <p><a parentName="p" {...{
            "href": "https://bit.ly/comm-dev-project-planning-template"
          }}>{`Project planning template`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Review the Resources`}</h3>
        <h4>{`Content`}</h4>
        <p>{`For writing or creating content, review the following resources to ensure the content meets standards.`}</p>
        <List mdxType="List">
          <Link to="/contribute/content/writing-style-guide/" mdxType="Link">Writing style guide </Link>
          <Link to="/contribute/content/reviewer-guide/" mdxType="Link">Reviewer guide </Link>
          <Link to="/contribute/content/" mdxType="Link">Visual style guide </Link>
        </List>
        <h4>{`Technical Resources`}</h4>
        <p>{`For developing with Maker, review this curated list of documentation, guides, tools, and APIs.`}</p>
        <p><a parentName="p" {...{
            "href": "https://awesome.makerdao.com/#developer-resources"
          }}>{`Maker Developer Resources`}</a></p>
        <p>{`For developing on this Portal, review this documentation, guides.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/"
          }}>{`Portal Resources`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Work Openly`}</h3>
        <p>{`Start work in a Google Doc so the Contributing Team can collaboratively review and leave feedback. Be sure to communicate regularly with the team and the wider community. It's strongly encouraged to share work, ask for feedback and keep everyone updated in the `}<a parentName="p" {...{
            "href": "https://chat.makerdao.com/channel/community-development"
          }}>{`community chat`}</a></p>
        <p>{`Keep the GitHub issue attached to your bounty up to date with comments and feedback.`}</p>
        <Box mdxType="Box">
        </Box>
        <h3>{`Submit a Final Draft`}</h3>
        <CTA icon="search" mdxType="CTA">
  If the work is content-based, it will have to be copied from a Google Doc to markdown. All content on the site is in markdown. Use Hack.md to create markdown content.
        </CTA>
        <p>{`When your work is ready for final approval, submit a pull request in GitHub and update the GitHub issue.`}</p>
        <p><a parentName="p" {...{
            "href": "//content/using-github/"
          }}>{`How to use GitHub`}</a></p>
        <p>{`Once a pull request has been approved, the Advisor will let give directions for requesting Dai.`}</p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Help Improve the Process`}</h3>
        <p>{`Once a bounty has been completed, let us know what went well and what didn’t. Suggest any ideas for improvements. This can be done on a call or via `}<a parentName="p" {...{
            "href": "https://chat.makerdao.com/channel/community-development"
          }}>{`community chat`}</a>{`.`}</p>
      </Box>
    </Process>
  <Tout image alt mdxType="Tout">
  <Box mdxType="Box">
        <h2>{`See Open Bounties`}</h2>
        <p>{`Ready to get to work? Check out which bounties currently need help.`}</p>
        <p><a parentName="p" {...{
            "href": "https://github.com/makerdao/community/projects/2?card_filter_query=label%3A%22help+wanted%22"
          }}>{`See open bounties`}</a></p>
  </Box>
  <Box mdxType="Box">
        <h2>{`Want To Improve this Page?`}</h2>
        <p>{`Learn how to suggest content changes to this page.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/content/"
          }}>{`Portal Resources`}</a></p>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      